.mainGame{
    
    .line{
        display: inline-block;
        position: absolute;
        z-index: 1;
        width: 40em;
        margin-top: 4.5em;
        height: .8em;
        background-color: rgba(77, 77, 77, 0.434);
    }
 
    
    
}
.mainGameFlexCont{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 17.5em;
    flex-wrap: wrap;
    transform: scale(1.5);

    .rock{
        border: 1em solid rgb(218, 43, 107);
        box-shadow: 4px 4px rgb(137, 13, 36);
    }
    .paper{
        border: 1em solid rgb(55, 43, 226);
        box-shadow: 4px 4px rgb(19, 21, 162);
    } 

    .scissors{
        border: 1em solid rgb(234, 175, 55);
        box-shadow: 4px 4px rgb(158, 110, 13);
    } 

   




    .rockPaperScissor:active{
        filter: drop-shadow(5px 5px 1px rgb(0, 0, 0));
        transform: translateY(.5em);
    }

    .rockPaperScissor{
        margin: auto 5em;
        padding: 2em;
        width: 3em;
        height: 3em;
        border-radius: 8em;
        background-color: whitesmoke;
        position: relative;
        z-index: 2;
        
        
        img{
            width: 2.5em;   
        }
        #rockSVG{
            width: 2.75em;
            /* to compensate for the rockSVG smaller base smaller size */

        }   
        
    } 
}
.Proceed{
    margin-top: 2em;
    margin-bottom: -11em;
    text-align: center;
    h1{
        font-size: 5em;
        background-color: whitesmoke;
        margin: auto 8.25em;
        border-radius: .25em;
        mix-blend-mode: screen;
    }
    #buttonMUI{
        margin-top: 1.5em;
        font-size: 2.5em;
    }
}